
.elem-first{
	@apply text-lg sm:text-xl pt-4;
}
.elem-second {
	@apply text-lg;
}

.elem-third {
	@apply text-lg;
}

.career-company{
	@apply text-lg sm:text-xl; 
}
