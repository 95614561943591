.main-img {
    @apply rounded-full object-cover shadow-lg;
    @apply lg:h-64 lg:w-64
           md:h-64 md:w-64
           sm:h-48 sm:w-48
           h-48 w-48;
}

.name-column-text {
@apply text-3xl sm:text-3xl md:text-4xl ;
}
