.home-button {
  @apply font-semibold hover:italic text-xl cursor-pointer block py-2;
}

.navbar-button {
  @apply hover:font-bold text-xl pr-4 md:pr-8 cursor-pointer block py-2 pl-3;
}

.standard-menu-navbar-button {
	@apply block text-lg px-4 py-2 cursor-pointer hover:italic;
}

.standard-menu-navbar-button-mobile {
	@apply  block text-lg py-2 cursor-pointer hover:italic;
}

.navbar-menu-button {
  @apply w-6 h-6;
}

/* .navbar-background{ */
/*     @apply dark:bg-[#232729] bg-[#FDFDFF]; */
/*   } */

  .body-property{
    @apply px-10 sm:px-16 md:px-24 lg:px-48 xl:px-72 2xl:px-96 ;
  }
