@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap');

@font-face {
  font-family: 'Hubot Sans';
  src:
    url('fonts/Hubot-Sans/Hubot-Sans.woff2') format('woff2 supports variations'),
    url('fonts/Hubot-Sans/Hubot-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}
html {
  font-family: 'Hubot Sans';
}

body {
  margin: 0;
  font-family: 'Hubot Sans';
  color: #232729;
  /*font-family: 'Patrick Hand SC', cursive;*/
   /*font-family: local("Inter"), url(./fonts/inter)
  /*-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
