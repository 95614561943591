
/*
* Publication setion
*/

.publication-title {
	@apply text-lg font-medium;
}


.publication-title-small {
	@apply text-base font-medium ;
}

.publication-authors{
	@apply italic font-light text-base;
}

.publication-date{
	@apply font-normal text-sm;
}

.publication-conference{
	@apply font-normal text-sm;
}
